import momentTimeZone from 'moment-timezone';

let serviceBaseUrl = window.cpd_env.services.url; // see public/env.js
if (!serviceBaseUrl) {
    const isLocalhost = window.location.origin.includes('localhost');
    const devServerURL = 'http://localhost:8080';
    // const devServerURL = 'https://vision.dev.europe-west1.cellpoint.app';
    serviceBaseUrl = isLocalhost ? devServerURL : window.location.origin;
}

export const API = serviceBaseUrl;

// IBe Configuration Server
export const IBEAPI = 'http://od.dev2.cellpointmobile.com:8989';

const isDynamicChange = false;
export const POST_DASHBOARD = `${API}/dashboard`;
export const POST_KPI_DASHBOARD = `${API}/mconsole/dashboard/Kpi`;
export const POST_CLIENT_CONFIG = `${API}/mconsole/client/v1/config?clientId=`;
export const POST_SYSTEM_META_DATA = `${API}/mconsole/systemMetaData`;
export const POST_LOGIN = `${API}/mconsole/app/login`;
export const GET_LOGOUT = `${API}/mconsole/app/logout`;
export const GET_PROFILE = `${API}/mconsole/app/profile`;
export const PUT_PROFILE = `${API}/mconsole/app/profile`;
export const POST_FORGOT_PASSWORD = `${API}/mconsole/app/forgot-password`;
export const PUT_RESET_PASSWORD = `${API}/mconsole/app/reset-password`;
export const POST_PERMISSIONS = `${API}/mconsole/permissions/permission-set?accountId=`;
export const GET_RESET_PASSWORD_TOKEN = `${API}/mconsole/app/reset-password?token=`;
export const POST_LOCATION = `${API}/mconsole/getCountries`;
export const POST_LIST_LOCATION = `${API}/mconsole/getLocation`;
export const POST_ADD_LOCATION = `${API}/mconsole/addLocation`;
export const POST_PROMOTION = `${API}/mconsole/promotion/search-promotions`;
export const POST_LIST_TRIGGER = `${API}/mconsole/pbl/searchTrigger`;
export const POST_MANUALTRIGGER_LIST = `${API}/mconsole/pbl/getCurrencyDiscountList`;
export const POST_ORDERSUMMARY = `${API}/mconsole/pbl/orderSummary`;
export const POST_CONFIGURE_MESSAGE = `${API}/mconsole/pbl/configureMessage`;
export const POST_PREVIEW_PUBLISH = `${API}/mconsole/pbl/previewPublish`;
export const POST_SCHEDULE = `${API}/mconsole/pbl/schedule`;
export const POST_DELETE = `${API}/mconsole/pbl/delete`;
export const POST_TRIGGER_EDIT = `${API}/mconsole/pbl/edit/manualtrigger`;
export const POST_LIST_AUTOTRIGGER = `${API}/mconsole/pbl/search/auto/trigger`;
export const POST_CONFIGUREAUTOTRIGGER = `${API}/mconsole/pbl/add/configuration/auto/trigger`;
export const POST_DELETEAUTOTRIGGER = `${API}/mconsole/pbl/delete/auto/trigger`;
export const POST_RETRIEVEAUTOTRIGGER = `${API}/mconsole/pbl/retrieve/auto/trigger/configurations`;
// export const POST_LIST_IBECONFIGURATIONS = `${IBEAPI}/get-client-configs`;
// export const POST_UPDATE_IBECONFIGURATIONS = `${IBEAPI}/update-client-configs`;
export const POST_CREATEPROMOTION = `${API}/mconsole/promotion/create-promotion`;
export const POST_UPDATEPROMOTION = `${API}/mconsole/promotion/update-promotion`;
export const POST_DELETEPROMOTION = `${API}/mconsole/promotion/delete-promotion`;
export const POST_VIEWPROMOTION = `${API}/mconsole/promotion/view-promotion`;
export const POST_TOPICLIST = `${API}/mconsole/broadcast/get-all-configurations`;

export const POST_CREATEEVENT = `${API}/mconsole/event/create-event`;
export const POST_UPDATEEVENT = `${API}/mconsole/event/update-event`;

export const POST_CREATE_DYNAMICBANNER = `${API}/mconsole/banner/create-banner`;
export const POST_UPDATE_DYNAMICBANNER = `${API}/mconsole/banner/update-banner`;
export const POST_DELETE_DYNAMICBANNER = `${API}/mconsole/banner/delete-banner`;

// Reports APIs
export const GET_SEARCHREPORTS = `${API}/mconsole/reporting/find-all-reports`;
export const GET_MYSUBSCRIPTIONS = `${API}/mconsole/reporting/subscriptions`;
export const GET_SUBSCRIBE = `${API}/mconsole/reporting/subscribe?`;
export const POST_SUBSCRIBE = `${API}/mconsole/reporting/subscribe`;
export const PUT_UPDATE_SUBSCRIPTION = `${API}/mconsole/reporting/subscription`;
export const POST_UNSUBSCRIBE = `${API}/mconsole/reporting/subscriptions`;

export const POST_SAVEFILTER = `${API}/mconsole/reporting/filter`;
export const GET_FILTER = `${API}/mconsole/reporting/filter?`;

export const POST_VIEW_REPORT = `${API}/mconsole/reporting/view`;
export const POST_DOWNLOAD_REPORT = `${API}/mconsole/reporting/download`;
export const POST_EMAIL_REPORT = `${API}/mconsole/reporting/email`;


// ManageTransaction APIs
export const POST_LIST_MANAGE_TRANSACTION = `${API}/mconsole/manage/get-all-transactions`;
export const GET_VIEW_TRANSACTION = `${API}/mconsole/manage/view-transaction`;
export const POST_ADD_TRANSACTION_NOTE = `${API}/mconsole/manage/add-transaction-note`;
export const POST_CAPTURE_REFUND_TRANSACTION = `${API}/mconsole/manage/capture-refund-transaction`;
export const POST_ADVANCE_SEARCH_TRANSACTION = `${API}/mconsole/manage/get-all-transactions`;
export const POST_UPDATE_STATUS_REVIEW = `${API}/mconsole/manage/update-fraud-review-status`;
export const POST_EMAIL_TRANSACTION_REPORT = `${API}/mconsole/manage/email-transaction-report`;
export const POST_UPDATE_ADDITIONAL_FILTERS = `${API}/mconsole/manage/update-additional-filters`;
export const POST_ADDITIONAL_FILTERS = `${API}/mconsole/manage/get-additional-filters`;
export const POST_DOWNLOAD_TRANSACTION_REPORT = `${API}/mconsole/manage/download-transaction-report`;
export const POST_LIST_REFUND_LINES_TRANSACTION = `${API}/mconsole/manage/get-all-refund-lines-transactions`;

// BroadCast API endpoints
export const POST_BROADCAST = {
  list: `${API}/mconsole/broadcast/get-all-configurations`,
  create: `${API}/mconsole/broadcast/create`,
  delete: `${API}/mconsole/broadcast/delete`,
  edit: `${API}/mconsole/broadcast/update`,
};

// GeoFence Endpoint
export const CREATE_GEOFENCE = `${API}/mconsole/geofence/create-geofence`;
export const SEARCH_GEOFENCE = `${API}/mconsole/geofence/search-geofence`;
export const VIEW_GEOFENCE = `${API}/mconsole/geofence/view-geofence`;
export const DELETE_GEOFENCE = `${API}/mconsole/geofence/delete-geofence`;

// GET AWS Object
export const GET_AWSOBJECT = `${API}/mconsole/promotion/getAWSObject`;


// NotificationEngine Endpoint
export const CREATE_NOTIFICATION = `${API}/mconsole/notification/create-notification`;
export const UPDATE_NOTIFICATION = `${API}/mconsole/notification/update-notification`;
export const GET_NOTIFICATION = `${API}/mconsole/notification/get-notification?`;
export const GET_META_DATA = `${API}/mconsole/notification/get-metadata`;
export const GET_ALL_NOTIFICATIONS = `${API}/mconsole/notification/get-allnotification`;


// Screen Configurations
export const GET_SCREENS = `${API}/mconsole/notification/get-screens`;
export const DELETE_SCREEN = `${API}/mconsole/notification/delete-screen`;
export const CREATE_SCREEN = `${API}/mconsole/notification/create-screen`;

// Rules COnfigurations
export const GET_RULES = `${API}/mconsole/routing/getRules`;
export const GET_RULES_META_DATA = `${API}/mconsole/routing/getMetaData`;
export const GET_DR_RULES_META_DATA = `${API}/mconsole/routing/getMetaData`;
export const GET_INSTALLMENT_RULES_META_DATA = `${API}/mconsole/routing/getMetaData`;
export const CREATE_RULE = `${API}/mconsole/routing/createRule`;
export const CREATE_FRAUD_RULE = `${API}/mconsole/fraudRouting/createRule`;
export const GET_RULE = `${API}/mconsole/routing/getRule`;
export const GET_FRAUD_RULE = `${API}/mconsole/fraudRouting/getRule`;
export const UPDATE_RULE = `${API}/mconsole/routing/updateRule`;
export const UPDATE_FRAUD_RULE = `${API}/mconsole/fraudRouting/updateRule`;
export const DELETE_RULE = `${API}/mconsole/routing/deleteRule?`;
export const DELETE_FRAUD_RULE = `${API}/mconsole/fraudRouting/deleteRule?`;
export const GET_ACTIVE_RULE = `${API}/mconsole/routing/getActiveRules?`;
export const DELETE_ACTIVE_RULE = `${API}/mconsole/routing/getDeleteRules?`;
export const CLIENT_CONFIG_DATA = `${API}/mconsole/mpoint/client/config`;
export const GET_CUSTOMER_TYPE = `${API}/mconsole/mprofile/getCustomerTypes`;
export const GET_SUBSCRIPTION = `${API}/mconsole/routing/getSubscription`;
export const UPDATE_MERCHANT_SUBSCRIPTION = `${API}/mconsole/routing/updateSubscription?`;
export const CONFIGURE_ROUTE = `${API}/mconsole/routing/configureRoute`;
export const DELETE_ROUTE = `${API}/mconsole/routing/deleteRoute`;
export const UPDATE_ROUTE = `${API}/mconsole/routing/updateRoute`;
export const GET_ROUTES = `${API}/mconsole/routing/getRoutes`;
export const GET_ROUTE = `${API}/mconsole/routing/getRoute`;
export const GET_ROUTE_METADATA = `${API}/mconsole/routing/getRouteMetaData`;
export const GET_IATA_CODES = `${API}/mconsole/mlocator/getIataCodes`;
export const GET_CLIENT_CONFIGURATION = `${API}/mconsole/merchantservices/getClientConfiguration`;
export const POST_CLIENT_CONFIGURATION = `${API}/mconsole/merchantservices/createClientConfiguration`;
export const DELETE_CLIENT_CONFIGURATION = `${API}/mconsole/merchantservices/deleteClientConfiguration?`;
export const GET_SYSTEM_METADATA = `${API}/mconsole/merchantservices/getSystemMetaData`;
export const GET_PAYMENT_METADATA = `${API}/mconsole/merchantservices/getPaymentMetaData`;
export const GET_ALL_CONFIGURATION_FOR_FX = `${API}/mconsole/merchantservices/getAllConfigurationForFx`;

export const GET_ADDON_CONFIGURATION = `${API}/mconsole/merchantservices/getAddonConfiguration`;
export const DELETE_ADDON_CONFIGURATION = `${API}/mconsole/merchantservices/deleteAddonConfiguration?`;
export const POST_ADDON_CONFIGURATION = `${API}/mconsole/merchantservices/createAddonConfiguration`;
export const GET_PSP_CONFIGURATION = `${API}/mconsole/merchantservices/pspconfig?`;
export const GET_ROUTE_CONFIGURATION = `${API}/mconsole/merchantservices/routeconfig?`;
export const GET_ROUTE_PSP_CONFIGURATION = `${API}/mconsole/merchantservices/routeconfigs?`;

export const UPDATE_ROUTE_PSP_CONFIGURATION = `${API}/mconsole/merchantservices/routeconfigs`;
export const DELETE_ROUTE_PSP_CONFIGURATION = `${API}/mconsole/merchantservices/routeconfig`;

export const GET_PROVIDER_CONFIGURATION = `${API}/mconsole/merchantservices/getProviderConfiguration`;
export const POST_PROVIDER_CONFIGURATION = `${API}/mconsole/merchantservices/createProviderConfiguration`;
export const DELETE_PROVIDER_CONFIGURATION = `${API}/mconsole/merchantservices/deleteProviderConfiguration?`;
export const GET_ROUTE_PSP_CONFIGURATION_BY_ID = `${API}/mconsole/merchantservices/routeconfig?`;
export const UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID = `${API}/mconsole/merchantservices/routeconfigs`;
export const DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID = `${API}/mconsole/merchantservices/routeconfig`;

export const GET_FRAUD_ROUTING_RULES = `${API}/mconsole/fraudRouting/getRules`;
export const GET_FRAUD_ROUTING_METADATA = `${API}/mconsole/fraudRouting/getMetaData`;

// FX Configurations
export const GET_PROVIDERS = `${API}/mconsole/foreignexchange/get-providers`;
export const ADD_FX_CONFIG = `${API}/mconsole/foreignexchange/add-configuration`;
export const GET_FX_CONFIG = `${API}/mconsole/foreignexchange/get-configurations`;
export const UPDATE_FX_CONFIG = `${API}/mconsole/foreignexchange/update-configuration`;

// Multiple Bin
export const GET_BIN_RANGE_METADATA = `${API}/mconsole/binRange/getMetaData`;
export const GET_BIN_RANGE_SEARCH = `${API}/mconsole/binRange/getSearch`;

// EventNotificatio endPoint
export const GET_ALL_FILTER_CONFIGURATIONS = `${API}/mconsole/eventNotification/getFilterConfigurations`;
export const GET_ALL_KEYS = `${API}/mconsole/eventNotification/getKeys`;
export const GET_ALL_FILTERS = `${API}/mconsole/eventNotification/getFilters`;
export const GET_ALL_QUEUES = `${API}/mconsole/eventNotification/getQueues`;
export const CREATE_FILTER_CONFIGURATION = `${API}/mconsole/eventNotification/addNewFilterConfiguration`;
export const DELETE_FILTER_CONFIGURATION = `${API}/mconsole/eventNotification/deleteFilterConfiguration?`;
export const GET_FILTER_CONFIG = `${API}/mconsole/eventNotification/getFilter?`;
export const UPDATE_FILTER_CONFIG = `${API}/mconsole/eventNotification/updateFilterConfiguration`;

// CPL
export const GET_PAYMENTLINK_METADATA = `${API}/mconsole/paymentLink/subscription-getmetadata`;
export const CREATE_PAYMENTLINK = `${API}/mconsole/paymentLink/create-paymentlink`;
export const GET_PAYMENTLINKS = `${API}/mconsole/paymentLink/get-all-paymentlinks`;
export const DELETE_PAYMENTLINK = `${API}/mconsole/paymentLink/delete-paymentlink`;
export const GET_PAYMENTLINKFILE = `${API}/mconsole/paymentLink/download-file`;
export const GET_PAYMENTLINK = `${API}/mconsole/paymentLink/get-paymentlink`;

export const CREATE_CLIENT_CONFIGURATION = `${API}/mconsole/merchantservices/createClientConfiguration`;
const dataString = isDynamicChange ? decodeURIComponent(window.location.search).split('&') : '';
const clientArr = dataString.length > 0 ? dataString[0].replace('?', '').split(',') : '';
export const clientId = clientArr.length >= 1 ? clientArr[0] : '10018';
export const arrLength = clientArr.length;
export const systemDateFrom = isDynamicChange ? dataString[1] : '2016-09-09 12:00:00';
export const systemDateTo = isDynamicChange ? dataString[2] : '2018-09-09 24:00:00';
export const colors = ['#FFB1C1', '#CEF7CE', '#FFF5DD', '#D7ECFB', '#B591A8', '#f3ab5e', '#a191a0', '#665bb9'];
export const WRONG_OPERATION = 400;
export const INTERNAL_SERVER_ERROR = 500;
export const NO_DATA_FOUND = 92;
export const dashboardMenu = [{ menuURL: '/GlobalDashboard', menuItem: 'Global' },
  { menuURL: '/TransactionDashboard', menuItem: 'Payment' },
  { menuURL: '/', menuItem: 'Revenue' },
  // { menuURL: '/ProductDashboard', menuItem: 'Product' },
  { menuURL: '/ProfileDashboard', menuItem: 'Profile' },
  { menuURL: '/PromotionsDashboard', menuItem: 'Promotions' },
  { menuURL: '/KPIsDashboard', menuItem: 'KPIs' }];
export const manageMenu = [{ menuURL: '/Permission', menuItem: 'Permission' },
  { menuURL: '/PermissionSet', menuItem: 'Permission Set' },
  { menuURL: '/Account', menuItem: 'Account' },
  { menuURL: '/User', menuItem: 'User' },
  { menuURL: '/Role', menuItem: 'Role' },
  { menuURL: '/Transactions', menuItem: 'Transactions' },
  { menuURL: '/Subscriptions', menuItem: 'Subscriptions' },
  { menuURL: '/Locations', menuItem: 'Locations' },
  { menuURL: '/Ibeconfigurations', menuItem: 'Ibeconfigurations' }];
export const reportsMenu = [{ menuURL: '/Reports', menuItem: 'View Reports' },
  { menuURL: '/MySubscripitions', menuItem: 'My Subscriptions' }];
export const configureMenu = [{ menuURL: '/MerchantOnboarding', menuItem: 'Merchant Onboarding' }];
export const vantageMenu = [{ menuURL: '/Promotions', menuItem: 'Promotions' },
  { menuURL: '/Events', menuItem: 'Events' },
  { menuURL: '/Geofence', menuItem: 'Geofence' }];
export const velocityMenu = [{ menuURL: '/PayByLink', menuItem: 'Pay By Link' },
  { menuURL: '/PaymentLink', menuItem: 'Payment Link' },
  { menuURL: '/Re-targeting', menuItem: 'Re-targeting' },
  { menuURL: '/DynamicRouting', menuItem: 'Dynamic Routing' },
  { menuURL: '/FX', menuItem: 'Currency / FX' },
  { menuURL: '/HostedPaymentPage', menuItem: 'Hosted Payment Page' },
  { menuURL: '/MerchantOnboardingLocale', menuItem: 'Merchant Onboarding' },
  { menuURL: '/ConfigureServices', menuItem: 'Configure Services' }];
export const consoleMenu = [
  { menuURL: '/MerchantOnboarding', menuItem: 'Merchant Onboarding' }];
export const psp = [{ id: '1', name: 'All' },
  { id: '2', name: 'MobilePay' },
  { id: '3', name: 'AliPay' },
  { id: '4', name: 'ApplePay' }];
export const product = [{ id: '1', name: 'All' },
  { id: '2', name: 'Air Ticket' },
  { id: '3', name: 'Car Rental' },
  { id: '4', name: 'Prepaid Baggage' }];
export const filter = [{ id: '1', name: 'Transactions' },
  { id: '2', name: 'Revenue' }, { id: '3', name: 'Products Sold' }];
export const storefront = [{ id: '1', name: 'iOS MBE' },
  { id: '2', name: 'Android MBE' }, { id: '3', name: 'Internet Booking Engine' }];
export const country = [{ id: '1', name: 'USA' },
  { id: '2', name: 'Denmark' },
  { id: '3', name: 'India' }];

// TOKEN BASED LOGIN
export const GET_TOKEN_LOGIN = `${API}/mconsole/app/token/login`;

// Detect user's timezone
const detectTimeZone = momentTimeZone.tz.guess();
export const formattedTimeZone = detectTimeZone ? momentTimeZone.tz(detectTimeZone).format('z') : '';


export const pageNavigationURL = {
  login: '/',
  loguot: '/sso/logout',
  resetPassword: '/reset-password',
  home: '/Home',
  profile: '/Profile',
  support: '/Support',
  transactionsDashboard: '/TransactionsDashboard',
  revenueDashboard: '/RevenueDashboard',
  Dashboard: '/Dashboard',
  productDashboard: '/ProductDashboard',
  profileDashboard: '/ProfileDashboard',
  fraudCheckDashboard: '/fraudCheckDashboard',
  promotionsDashboard: '/PromotionsDashboard',
  notificationDashboard: '/NotificationDashboard',
  KPIsDashboard: '/KPIsDashboard',
  payByLink: '/PayByLink',
  paymentLink: '/PaymentLink',
  manualTrigger: '/Subscription',
  viewPaymentLink: '/ViewSubscription',
  autoTrigger: '/Re-targeting',
  dynamicRouting: '/DynamicRouting',
  FOP: '/FOP',
  installment: '/Installments',
  fx: '/FX',
  FMS: '/FMS',
  PreAuth: '/PreAuth',
  PostAuth: '/PostAuth',
  EditPreAuth: '/EditPreAuth',
  ViewPreAuth: '/ViewPreAuth',
  EditPostAuth: '/EditPostAuth',
  ViewPostAuth: '/ViewPostAuth',
  hostedPaymentPage: '/HostedPaymentPage',
  merchantOnboardingLocale: '/MerchantOnboardingLocale',
  merchantOnboardingCountry: '/MerchantOnboardingCountry',
  configureServices: '/ConfigureServices',
  promotionsAndEvents: '/Promotions',
  createPromotions: '/CreatePromotions',
  viewPromotions: '/ViewPromotions',
  editPromotions: '/EditPromotions',
  createEvents: '/CreateEvents',
  viewEvents: '/viewEvents',
  editEvents: '/EditEvents',
  createDynamicBanner: '/CreateDynamicBanner',
  viewDynamicBanner: '/ViewDynamicBanner',
  editDynamicBanner: '/EditDynamicBanner',
  topics: '/Topics',

  geofence: '/GeoFence',
  createGeoFence: '/CreateGeoFence',
  viewGeoFence: '/ViewGeoFence',

  reports: '/Reports',
  mySubscriptions: '/Subscriptions',
  showReport: '/ShowReport',
  locations: '/Locations',
  ibeconfigurations: '/Ibeconfigurations',

  createRule: '/CreateRule',
  CreatePreAuthFraudRule: '/CreatePreAuthFraudRule',
  CreatePostAuthFraudRule: '/CreatePostAuthFraudRule',
  createEventNotification: '/CreateEventNotification',
  EditRule: '/EditRule',
  ViewRule: '/ViewRule',
  EditInstallment: '/EditInstallment',
  ViewInstallment: '/ViewInstallment',
  createFOP: '/CreateFOP',
  createInstallments: '/CreateInstallments',
  EditFop: '/EditFop',
  ViewFop: '/ViewFop',
  static: '/static',
  createRoute: '/CreateRoute',
  EditRoute: '/EditRoute',
  ViewRoute: '/ViewRoute',
  notificationConfigure: '/NotificationConfigure',
  notificationEngine: '/NotificationEngine',
  travelbank: '/Travelbank',
  screenConfigurations: '/ScreenConfigurations',
  manageTransaction: '/transactions',
  merchantOnboarding: '/MerchantOnboarding',
  eventNotification: '/EventNotification',
  editEventNotification: '/EditEventNotification',
  viewEventNotification: '/ViewEventNotification',
  popSetup: '/PopSetup',
  merchantSetup: '/MerchantSetup',
  createMerchantSubscription: '/CreateMerchantSubscription',
  MerchantSubscription: '/MerchantSubscription',
  vasSetup: '/VasSetup',
  ViewReport: '/viewReport',
};
