import { actionTypes as types, urls } from '../constants';
import { post, get, put } from '../helpers';

export const loginRequest = data => dispatch => {
  dispatch({ type: types.LOGIN_REQUEST });
  post({
    url: urls.POST_LOGIN,
    success: types.LOGIN_SUCCESS,
    failure: types.LOGIN_FAILURE,
    dispatch,
    body: data,
  });
};

export const forgotPasswordRequest = data => dispatch => {
  dispatch({ type: types.FORGOT_PASSWORD_REQUEST });
  post({
    url: urls.POST_FORGOT_PASSWORD,
    success: types.FORGOT_PASSWORD_SUCCESS,
    failure: types.FORGOT_PASSWORD_FAILURE,
    dispatch,
    body: data,
  });
};

export const resetPasswordTokenRequest = data => dispatch => {
  dispatch({ type: types.RESET_PASSWORD_TOKEN_REQUEST });
  const url = `${urls.GET_RESET_PASSWORD_TOKEN}${data}`;
  get({
    url,
    success: types.RESET_PASSWORD_TOKEN_SUCCESS,
    failure: types.RESET_PASSWORD_TOKEN_FAILURE,
    dispatch,
  });
};

export const resetPasswordRequest = data => dispatch => {
  dispatch({ type: types.RESET_PASSWORD_REQUEST });
  put({
    url: urls.PUT_RESET_PASSWORD,
    success: types.RESET_PASSWORD_SUCCESS,
    failure: types.RESET_PASSWORD_FAILURE,
    dispatch,
    body: data,
  });
};

export const tokenBasedLoginRequest = data => dispatch => {
  dispatch({ type: types.TOKEN_LOGIN_REQUEST });
  get({
    url: urls.GET_TOKEN_LOGIN,
    success: types.TOKEN_LOGIN_SUCCESS,
    failure: types.TOKEN_LOGIN_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
    setContentTypeHeder: false,
  });
};

export const logoutRequest = data => dispatch => {
  dispatch({ type: types.LOGOUT_REQUEST });
  const url = urls.GET_LOGOUT;

  get({

    url,
    success: types.LOGOUT_SUCCESS,
    failure: types.LOGOUT_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
    setContentTypeHeder: false,
  });
};
